import React from 'react'
import image_1 from './junkcar.jpg'
import { FaCheck } from 'react-icons/fa6'

const JunkCar = () => {
  return (
    <div className='klt_about'>
        <div className='klt_about_row'>
            <div className='klt_about_col'>
                <img src={image_1} alt='man-towing-car' />
            </div>
            <div className='klt_about_col'>
                <h2 className='klt_colored_text'>
                    JUNK CARS
                </h2>
                <h3>
                    We Buy and Tow Junk Cars in Any Condition
                </h3>
                <p>
                KL Towing offers a hassle-free junk car removal service, buying and towing vehicles in any condition. Whether your car is old, damaged, or non-running, we provide competitive cash offers with free towing. Our process is quick, transparent, and convenient—get paid on the spot with no hidden fees.
                </p>
                <div className='klt_spacer'></div>
                <div>
                    <div>
                        <FaCheck color='white' size={20} />
                    </div>
                    <div>
                        <h3>
                            We Buy Junk Cars
                        </h3>
                        <p>
                            KL Towing offers top cash for junk, damaged, or unwanted vehicles in any condition. No need for repairs or listings—just a quick, hassle-free sale with instant payment.
                        </p>
                    </div>
                </div>
                <div className='klt_spacer'></div>
                <div>
                    <div>
                        <FaCheck color='white' size={20} />
                    </div>
                    <div>
                        <h3>
                            We Tow Junk Cars
                        </h3>
                        <p>
                            Get free towing for your junk car, no matter its condition. Our team handles everything, ensuring a smooth, stress-free removal process with no hidden fees.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default JunkCar