import React from 'react'
import Hero from './hero/Hero'
import InfoBar from './info/InfoBar'
import About from './about/About'
import Services from './services/Services'
import Testimonial from './testimonials/Testimonial'
import Faq from './faq/Faq'
import JunkCar from './junkcars/JunkCar'

const Home = () => {
  return (
    <div>
      <Hero />
      <InfoBar />
      <About />
      <JunkCar />
      <Services />
      <Testimonial />
      <div className='klt_spacer'></div>
      <Faq />
      <InfoBar />
    </div>
  )
}

export default Home
