import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import AWS from 'aws-sdk';
import { jsPDF } from 'jspdf';
import './admin.css';
import Logo from './kl-towing-logo.png';


console.log(process.env.REACT_APP_CLOUDFLARE_ACCESS_KEY)
// Configure AWS SDK for Cloudflare R2
AWS.config.update({
    accessKeyId: `${process.env.REACT_APP_CLOUDFLARE_ACCESS_KEY}`, // Use environment variables
    secretAccessKey: `${process.env.REACT_APP_CLOUDFLARE_SECRET_KEY}`, // Use environment variables
    region: 'auto',
    endpoint: `https://${process.env.REACT_APP_CLOUDFLARE_ACCOUNT_ID}.r2.cloudflarestorage.com`, // Use environment variables
});

const s3 = new AWS.S3();

const AdminAuth = ({ adminConnected }) => {
    const [passcode, setPasscode] = useState('');
    const correctPasscode = '1121Hsbc@';

    const handleInput = (e) => {
        setPasscode(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (passcode === correctPasscode) {
            adminConnected(true); // Grant admin access
        } else {
            alert('Incorrect passcode. Please try again.');
        }
    };

    return (
        <div className="klt_admin">
            <h1 style={{
                textAlign:'center'
            }}>Welcome Back Sunny</h1>
            <p style={{
                textAlign:'center'
            }}>Kindly Enter your passcode</p>
            <form className='invoice-form-cont' onSubmit={handleSubmit}>
                <label htmlFor="password">Passcode</label>
                <input
                    type="password"
                    placeholder="Enter Admin Passcode"
                    onChange={handleInput}
                    value={passcode}
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

const InvoiceForm = () => {
    const [ loading, setLoading ] = useState(false)
    const [formData, setFormData] = useState({
        customerName: '',
        email: '',
        price: '',
        productOrService: '',
        date: '',
        pickupAddress: '',
        dropoffAddress: '',
        miles: '',
        remark: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleGenerateInvoice = async (e) => {
        e.preventDefault();
        setLoading(true)
        const {
            customerName,
            email,
            price,
            productOrService,
            date,
            pickupAddress,
            dropoffAddress,
            miles,
            remark
        } = formData;

        const doc = new jsPDF();
        doc.addImage(Logo, "PNG", 14, 10, 30, 30); // Position: (x: 14, y: 10), Size: 30x30mm

        // Title and company details (left-aligned)
        doc.setFont("helvetica", "extrabold");
        doc.setFontSize(40);
        doc.text("Invoice", 14, 50);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.text("Loganville, GA 30052", 14, 60);
        doc.text("470-475-6564", 14, 66);
        doc.text("kltowing2024@gmail.com", 14, 72);

        // Billed To section
        doc.setFont("helvetica", "bold");
        doc.text("BILLED TO:", 14, 90);
        doc.setFont("helvetica", "normal");
        doc.text(`Customer's Name: ${customerName}`, 14, 98);
        doc.text(`Pickup Address: ${pickupAddress}`, 14, 104);
        doc.text(`Drop off Address: ${dropoffAddress}`, 14, 116);

        // Invoice Details
        doc.setFont("helvetica", "bold");
        doc.text("Invoice #:", 140, 90);
        doc.setFont("helvetica", "normal");
        doc.text("8737473873", 160, 90);

        doc.setFont("helvetica", "bold");
        doc.text("Invoice Date:", 140, 98);
        doc.setFont("helvetica", "normal");
        doc.text(`${date}`, 165, 98);

        // Description Table
        doc.setFont("helvetica", "bold");
        doc.text("SERVICE DESCRIPTION", 14, 140);
        doc.text("MILES", 110, 140);
        doc.text("AMOUNT($)", 160, 140);
        doc.setDrawColor(0, 0, 0);
        doc.line(14, 142, 196, 142); // Draw table header line

        doc.setFont("helvetica", "normal");
        doc.text(`${productOrService}`, 14, 150);
        doc.text(`${miles}`, 110, 150, { align: "right" });
        doc.text(`${price}`, 196, 150, { align: "right" });

        // Subtotal and Total
        doc.line(14, 160, 196, 160); // Line above total
        doc.setFont("helvetica", "bold");
        doc.text("Subtotal", 160, 170, { align: "right" });
        doc.text(`${price}`, 196, 170, { align: "right" });

        doc.setFont("helvetica", "bold");
        doc.text("Grand Total", 160, 180, { align: "right" });
        doc.text(`${price}`, 196, 180, { align: "right" });

        // Payment Info
        doc.setFont("helvetica", "bold");
        doc.text("PAYMENT INFORMATION", 14, 200);
        doc.setFont("helvetica", "normal");
        doc.text(`Payment Due Date: ${date}`, 14, 208);

        // Remark
        doc.setFont("helvetica", "italic");
        doc.text(`REMARK: ${remark}.\nThanks for choosing KL Towing`, 14, 230);

        // Save PDF Locally
        doc.save(`Invoice_${customerName}.pdf`);

        const pdfBlob = doc.output("blob");
        const sanitizedCustomerName = customerName.replace(/\s+/g, '_');

        // Upload PDF to Cloudflare R2
        const params = {
            Bucket: 'kltowing', // Use environment variables
            Key: `invoices/Invoice_${sanitizedCustomerName}_${Date.now()}.pdf`, // File path in the bucket
            Body: pdfBlob,
            ContentType: 'application/pdf',
        };

        try {
            // const uploadResult = await s3.upload(params).promise();
            // console.log('PDF uploaded successfully:', uploadResult.Location);

            // // Example: Open the PDF in a new tab
            // window.open(uploadResult.Location, "_blank");

            const uploadResult = await s3.upload(params).promise();
            console.log('PDF uploaded successfully:', uploadResult);

            // Construct the public URL manually
            const publicUrl = `https://pub-51553d127e6d4ea8bd78fa1dc6ef90b1.r2.dev/${params.Key}`;
            console.log('Public PDF URL:', publicUrl);

            // Example: Open the PDF in a new tab
            window.open(publicUrl, "_blank");


            // Send Email with EmailJS
            await emailjs.send(
                `${process.env.REACT_APP_EMAILJS_SERVICE_ID}`, // Replace with your EmailJS Service ID
                `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`, // Replace with your EmailJS Template ID
                {
                    customer_name: customerName,
                    email: email,
                    product_or_service: productOrService,
                    price: price,
                    date: date,
                    pickupAddress: pickupAddress,
                    dropoffAddress: dropoffAddress,
                    pdf_attachment: publicUrl, // Use the uploaded PDF URL
                    miles: miles,
                    remark: remark,
                },
                `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY}` // Replace with your EmailJS Public Key
            );
            setLoading(false);
            alert('Invoice sent successfully!');
        } catch (error) {
            setLoading(false);
            console.error('Error uploading PDF or sending email:', error);
            alert('Failed to upload invoice or send email.');
        }
    };

    return (
        <div className="invoice-form">
            <h2 style={{ textAlign: 'center' }}>Create Invoice</h2>
            <form className='invoice-form-cont' onSubmit={handleGenerateInvoice}>
                <input
                    type="text"
                    name="customerName"
                    placeholder="Customer Name"
                    value={formData.customerName}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Customer Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="productOrService"
                    placeholder="Product/Service"
                    value={formData.productOrService}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="pickupAddress"
                    placeholder="Pickup Address"
                    value={formData.pickupAddress}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="dropoffAddress"
                    placeholder="Dropoff Address"
                    value={formData.dropoffAddress}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="miles"
                    placeholder="Miles"
                    value={formData.miles}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="number"
                    name="price"
                    placeholder="Price"
                    value={formData.price}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleInputChange}
                    required
                />
                <textarea
                    name="remark"
                    placeholder="Additional Remark"
                    value={formData.remark}
                    onChange={handleInputChange}
                    required
                />
                <button type="submit">{!loading ? "Generate & Send Invoice" : "Sending..."}</button>
            </form>
        </div>
    );
};

const Admin = () => {
    const [admin, setAdmin] = useState(false);

    return (
        <div>
            {admin ? <InvoiceForm /> : <AdminAuth adminConnected={setAdmin} />}
        </div>
    );
};

export default Admin;